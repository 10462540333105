.footer-content {
  display: flex;
  flex-direction: row;
  height: 200px;
  /* background: rgb(2, 0, 36); */
  background: linear-gradient(
    45deg,
    transparent,
    var(--color-primary-variant),
    transparent
  );

  /* border: 1px solid var(--color-primary); */
}
.logo img {
  width: 55%;
  height: auto;
  margin-top: 20px;
  padding: 5px;
  margin-left: 13px;
}

.footer-list {
  display: flex;
  width: 300px;
  gap: 20px;
  padding: 14px;
}

.footer-list li {
  font-size: 12px;
  letter-spacing: 2px;
  color: var(--color-primary);
}

.footer-list h3 {
  padding-bottom: 4px;
  letter-spacing: 1px;
  color: var(--color-light);
}

.social-footer li {
  display: inline-block;
  font-size: 22px;
  padding: 6px;
}

.line {
  background: var(--color-primary);

  height: 1px;
  width: 60%;
  position: absolute;
  /* right: 500px; */
  margin-top: 150px;
  margin-left: 230px;
}
.line p {
  color: var(--color-light);
  font-size: 10px;
  text-align: end;
  padding: 5px;
  letter-spacing: 2px;
}

@media screen and (max-width: 1024px) {
  .line {
    width: 420px;
  }
}
@media screen and (max-width: 600px) {
  .footer-list {
    display: none;
  }

  .line {
    display: none;
  }
  .logo {
    margin: 0 auto;
    width: 300px;
    padding-left: 20px;
  }
}
