header {
  height: 100vh;
  padding-top: 7rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}
.close {
  display: none;
}

.info {
  width: 68%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  position: relative;
  top: 10%;
  margin-left: 100px;
}

.info h1 span {
  color: var(--color-primary);
}
.info h5 {
  font-size: 30px;
}
.info h1 {
  font-size: 70px;
}

.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.socialmedia {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  position: absolute;
  left: 0;
  bottom: 20%;
  font-size: 30px;
}
.socialmedia a:hover {
  transform: scale(1.2);
  transition: 1s all ease;
}
.socialmedia::after {
  content: '';
  width: 1px;
  height: 120px;
  background-color: var(--color-primary);
  align-self: center;
}
.socialmedia::before {
  content: '';
  width: 1px;
  height: 120px;
  background-color: var(--color-primary);
  align-self: center;
}

.socialmedia a {
  color: var(--color-primary);
}

/* .border {
  border-bottom: 1px solid var(--color-bg-variant);
} */

.me img {
  width: 400px;
  height: 600px;

  margin-left: 70%;

  /* padding-bottom: 30px; */
  position: absolute;
  border-radius: 1000px 0px 0px 0px;
  bottom: 20%;
  background: linear-gradient(var(--color-primary), transparent);
}

.me {
  height: 300px;
}

.scroll__down {
  width: 150px;
  position: absolute;
  right: 0%;
  color: var(--color-light);
  left: 100%;
  bottom: 30%;
  font-size: 20px;
  transform: rotate(90deg);
  /* margin-left: 30px; */
}

@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }

  .info {
    position: absolute;
    top: 0;
    margin: 0;
  }
  .info h5 {
    font-size: 30px;
  }
  .info h1 {
    font-size: 45px;
  }
  .me img {
    width: 300px;
    height: 300px;
    display: flex;

    border-radius: 200px 0px 0px 0px;
  }
  .cta {
    margin-top: 100px;
    padding: 10px;
  }
  .socialmedia {
    font-size: 18px;
  }
}

.info h1 {
  animation: name 3s;
}
.info :first-child {
  animation: hello 0.3s;
}
.info h5 {
  animation: title 10s;
}
@keyframes name {
  0% {
    transform: translateX(100px);
  }

  50% {
    transform: translateX(-80px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes title {
  0% {
    transform: translateX(1000px);
  }

  58% {
    transform: rotate(180deg);
  }

  70% {
    transform: scale(1.3);
  }
  50% {
    transform: translateX(-80px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes hello {
  0% {
    transform: rotate(180deg);
  }

  50% {
    transform: translateX(-180deg);
  }
  100% {
    transform: translateX(0px);
  }
}

@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }
  .scroll__down,
  .socialmedia {
    display: none;
  }
  .info {
    position: absolute;
    top: 0;
    margin: 0;
  }
  .info h5 {
    font-size: 18px;
  }
  .info h1 {
    font-size: 25px;
  }
  .me img {
    width: 400px;
    height: 400px;
    /* display: block; */
    margin: 0 auto;
    border-radius: 200px 0px 0px 0px;
  }
  .cta {
    margin-top: 180px;
  }
}
